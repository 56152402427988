import React from 'react';
import { graphql} from 'gatsby';
import Privacy from '../components/ExternalPage';
import { themeMode } from '../constants/strings'

export const query = graphql`
    query{
        content: allGhostPage (
            filter: { 
                tags : { elemMatch : { name: { eq: "#privacy" }}}
            }
        ){
            nodes {
                title
                html
            }
        }
    }
`;

const PrivacyPage = props => {
    let content = props.data.content.nodes[0].html;
    let title = props.data.content.nodes[0].title;
    
    const location = props.location;

    // This has been kept to avoid rehydration issues
    // this page is being used in Consumer App to show TnC without header and footer
    if (typeof window == `undefined`) {
        return <Privacy
            hideLayout={true}
            data={content}
            title={title}
        />
    }
    
    return (
        <Privacy
            hideLayout={location.search.includes(themeMode)}
            data={content}
            title={title}
        />
    )
}

export default PrivacyPage;
