import React from 'react';
import Layout from '../common/Layout';
import Heading from '../common/ui/Heading';
import { Image, ImageContainer } from '../common/ui/Image';
import { ExternalPageContainer, HeadingContainer } from './Component';

const ExternalPage = ({ data, title, title2 = null, logo, hideLayout }) => {
    return (
        <Layout noHeader={hideLayout} noFooter={hideLayout}>
            <ExternalPageContainer
                className="external_pages"
            >
                <HeadingContainer>
                    <Heading
                        align="center"
                        margin="0px 0px 30px"
                        sMargin="20px 0px 20px"
                        size="sm"
                        flex="1"
                    >
                        {title}
                        <br/>
                        {title2}
                    </Heading>
                    {logo && <ImageContainer maxWidth="100px" minWidth="70px">
                        <Image src={logo}/>
                    </ImageContainer>}
                </HeadingContainer>
                <div dangerouslySetInnerHTML={{__html:data}}/>
            </ExternalPageContainer>
        </Layout>
    )
}

export default ExternalPage;
